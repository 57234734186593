// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.nav-tabs {
    border: 0;
    padding: $padding-base-vertical $padding-base-horizontal;

    > .nav-item{
        > .nav-link{
            color: $white;
            margin: 0;
            margin-right: 5px;
            background-color: $transparent-bg;
            border-radius: 30px;
            padding: $padding-btn-vertical $padding-round-horizontal;
            line-height: $line-height-sm;
            font-family: 'Catamaran';
            font-size: 1.333rem;
            font-weight: 500;                      
            &:hover {
                background-color: $transparent-bg;
                border: 2px solid darken($flame,5%);
                color: rgba($white, 0.8);
            }

            &.active{
                border: 2px solid $white;
                border-radius: 30px;
            }

            i.tim-icons{
                font-size: 14px;
                position: relative;
                top: -1px;
                margin-right: 3px;
            }
        }

        &.disabled > .nav-link,
        &.disabled > .nav-link:hover {
            color: rgba(255,255,255,0.5);
        }
    }

    &.nav-tabs-neutral{
        > .nav-item{
            > .nav-link{
                color: $white;

                &.active{
                    border-color: $opacity-5;
                    color: $white;
                }
            }
        }
    }

    &.nav-tabs-primary{
        > .nav-item{
            > .nav-link{
                &.active{
                    border-color: $primary;
                    color: $primary;
                }
            }
        }
    }

    &.nav-tabs-info{
        > .nav-item{
            > .nav-link{
                &.active{
                    border-color: $info;
                    color: $info;
                }
            }
        }
    }

    &.nav-tabs-danger{
        > .nav-item{
            > .nav-link{
                &.active{
                    border-color: $danger;
                    color: $danger;
                }
            }
        }
    }

    &.nav-tabs-warning{
        > .nav-item{
            > .nav-link{
                &.active{
                    border-color: $warning;
                    color: $warning;
                }
            }
        }
    }

    &.nav-tabs-success{
        > .nav-item{
            > .nav-link{
                &.active{
                    border-color: $success;
                    color: $success;
                }
            }
        }
    }

    &.nav-tabs-flame{
        > .nav-item{
            > .nav-link{
                color: $flame;
                cursor: pointer;
                &.active{
                    border-color: $flame;
                    border-width: 2px;
                }
                &.hover{
                    border-color: $flame;
                    border-width: 2px;
                }
            }
        }
    }

}
